import type { MemosObject } from "../../QueryParser";
import { ReceiptQueryObjectUpdater } from "../../ReceiptQueryObjectUpdater";
import { TxRowData } from "./ChainDataFetcher";
import { TxRow } from './TxRow';
import { useState } from 'react';


//Currently unused but might be useful in the future:
export const addOrRemoveElementFromArray = function<T extends string | number>(
	arraySetter: React.Dispatch<React.SetStateAction<T[]>>,
	target: T,
	shouldBeThere: boolean
) {
	arraySetter((arrayLowercase: T[]) => {
		const indexOfID = arrayLowercase.indexOf(target);
		if(indexOfID < 0 && shouldBeThere) {
			arrayLowercase.push(target);
		}
		if(indexOfID >= 0 && !shouldBeThere) {
			arrayLowercase.splice(indexOfID, 1);
		}
		//Have to make it a new array so React recognizes state change and re-renders.
		//See https://stackoverflow.com/a/56266640
		return [...arrayLowercase];
	});
};

export const updateDraft = function(
	draftsSetter: React.Dispatch<React.SetStateAction<MemosObject>>,
	key: string,
	newDraft: string | undefined,
) {
	draftsSetter((drafts: MemosObject) => {
		//Have to copy to a new object so React recognizes state change and re-renders.
		//See https://stackoverflow.com/a/56266640
		return ReceiptQueryObjectUpdater.copyAndUpdateMemosObject(
			drafts,
			key,
			true,
			newDraft,
			false
		).newMemosObject;
	});
};

export const TxTable = function(props: {
	txRows: TxRowData[];
	isLocked: boolean;
	updateTxMemo: ((txID: string, newMemoTrimmed: string) => Promise<void>);
	updateAcctLabel: ((addressOrOnChainLabel: string, newLabelTrimmed: string) => Promise<void>);
	setFilterTo?: ((addressOrOnChainLabel?: string) => Promise<string[]>);
	useNarrowVersion: boolean;
	queriedAddressesLowercase: string[];
	anySpecifiedTxes: boolean;
	timeRangeConstraintType?: 'time' | 'block';
}) {
	//Might be able to improve efficiency a tiny bit by using a sorted data structure,
	//but the array will usually be 0 or 1 elements long
	//so the overhead and complexity of doing so isn't likely worth it.
	const [txMemoDrafts, setTxMemoDrafts] = useState<MemosObject>({});
	const [txFromDrafts, setTxFromDrafts] = useState<MemosObject>({});
	const [txToDrafts, setTxToDrafts] = useState<MemosObject>({});
	const updateTxMemoDraft = function(
		txHash: string,
		newDraft: string | undefined, // undefined (default) if not editing
	) {
		updateDraft(
			setTxMemoDrafts,
			txHash.toLowerCase(),
			newDraft
		);
	};
	const updateTxFromDraft = function(
		txHash: string,
		newDraft: string | undefined, // undefined (default) if not editing
	) {
		updateDraft(
			setTxFromDrafts,
			txHash.toLowerCase(),
			newDraft
		);
	};
	const updateTxToDraft = function(
		txHash: string,
		newDraft: string | undefined, // undefined (default) if not editing
	) {
		updateDraft(
			setTxToDrafts,
			txHash.toLowerCase(),
			newDraft
		);
	};
	if(props.useNarrowVersion === true) {
		return (
			<TxTableNarrow
				txRows = {props.txRows}
				isLocked = {props.isLocked}
				txMemoDrafts = {txMemoDrafts}
				txFromDrafts = {txFromDrafts}
				txToDrafts = {txToDrafts}
				updateTxMemoDraft = {updateTxMemoDraft}
				updateTxFromDraft = {updateTxFromDraft}
				updateTxToDraft = {updateTxToDraft}
				updateAcctLabel = {props.updateAcctLabel}
				updateTxMemo = {props.updateTxMemo}
				setFilterTo = {props.setFilterTo}
				queriedAddressesLowercase = {props.queriedAddressesLowercase}
				anySpecifiedTxes = {props.anySpecifiedTxes}
				timeRangeConstraintType = {props.timeRangeConstraintType}
			/>
		);
	} else {
		return (
			<TxTableWide
				txRows = {props.txRows}
				isLocked = {props.isLocked}
				txMemoDrafts = {txMemoDrafts}
				txFromDrafts = {txFromDrafts}
				txToDrafts = {txToDrafts}
				updateTxMemoDraft = {updateTxMemoDraft}
				updateTxFromDraft = {updateTxFromDraft}
				updateTxToDraft = {updateTxToDraft}
				updateAcctLabel = {props.updateAcctLabel}
				updateTxMemo = {props.updateTxMemo}
				setFilterTo = {props.setFilterTo}
				queriedAddressesLowercase = {props.queriedAddressesLowercase}
				anySpecifiedTxes = {props.anySpecifiedTxes}
				timeRangeConstraintType = {props.timeRangeConstraintType}
			/>
		);
	}
}

const TxTableWide = function(props: {
	txRows: TxRowData[];
	isLocked: boolean;
	txMemoDrafts: MemosObject;
	txFromDrafts: MemosObject;
	txToDrafts: MemosObject;
	updateTxMemoDraft: (txID: string, newDraft: string | undefined) => void;
	updateTxFromDraft: (txID: string, newDraft: string | undefined) => void;
	updateTxToDraft: (txID: string, newDraft: string | undefined) => void;
	updateTxMemo: ((txID: string, newMemoTrimmed: string) => Promise<void>);
	updateAcctLabel: ((addressOrOnChainLabel: string, newLabelTrimmed: string) => Promise<void>);
	setFilterTo?: ((addressOrOnChainLabel?: string) => Promise<string[]>);
	queriedAddressesLowercase: string[];
	anySpecifiedTxes: boolean;
	timeRangeConstraintType?: 'time' | 'block';
}) {
	return (
			<table className='txReceiptsTable'>
				<thead>
					<tr>
						<td>
							Transaction ID
						</td>
						<td>
							From
						</td>
						<td>
							To
						</td>
						<td title='This transaction took place on'>
							Date/Time
						</td>
						<td>
							ETH sent<br />(ETH)
						</td>
						<td>
							Tx fee<br />(ETH)
						</td>
						<td title='Transaction-time USD value of native ETH sent, rounded to the penny.'>
							ETH sent<br />(USD)
						</td>
						<td title='Transaction-time USD value of transaction fee, rounded to the penny.'>
							Tx fee<br />(USD)
						</td>
					</tr>
				</thead>
				<tbody>
					{
						props.txRows.map((txRow) => <TxRow
							key = {txRow.txID + 'Wrapper'}
							isLocked = {props.isLocked}
							txMemoDraft = {props.txMemoDrafts[txRow.txID.toLowerCase()]}
							txFromDraft = {props.txFromDrafts[txRow.txID.toLowerCase()]}
							txToDraft = {props.txToDrafts[txRow.txID.toLowerCase()]}
							updateTxMemoDraft = {function(newDraft: string | undefined) {
								props.updateTxMemoDraft(txRow.txID, newDraft);
							}}
							updateTxFromDraft = {function(newDraft: string | undefined) {
								props.updateTxFromDraft(txRow.txID, newDraft);
							}}
							updateTxToDraft = {function(newDraft: string | undefined) {
								props.updateTxToDraft(txRow.txID, newDraft);
							}}
							rowData = {txRow}
							onMemoChange = {function(newMemoTrimmed: string) {
								return props.updateTxMemo(txRow.txID, newMemoTrimmed);
							}}
							useNarrowVersion = {false}
							updateAcctLabel = {props.updateAcctLabel}
							setFilterTo = {props.setFilterTo}
							queriedAddressesLowercase = {props.queriedAddressesLowercase}
							anySpecifiedTxes = {props.anySpecifiedTxes}
							timeRangeConstraintType = {props.timeRangeConstraintType}
						/>)
					}
				</tbody>
			</table>
	);
}

const TxTableNarrow = function(props: {
	txRows: TxRowData[];
	isLocked: boolean;
	txMemoDrafts: MemosObject;
	txFromDrafts: MemosObject;
	txToDrafts: MemosObject;
	updateTxMemoDraft: (txID: string, newDraft: string | undefined) => void;
	updateTxFromDraft: (txID: string, newDraft: string | undefined) => void;
	updateTxToDraft: (txID: string, newDraft: string | undefined) => void;
	updateTxMemo: ((txID: string, newMemoTrimmed: string) => Promise<void>);
	updateAcctLabel: ((addressOrOnChainLabel: string, newLabelTrimmed: string) => Promise<void>);
	setFilterTo?: ((addressOrOnChainLabel?: string) => Promise<string[]>);
	queriedAddressesLowercase: string[];
	anySpecifiedTxes: boolean;
	timeRangeConstraintType?: 'time' | 'block';
}) {
	return (
		<ul className='txReceiptsList'>
				{
					props.txRows.map((txRow) => <TxRow
						key = {txRow.txID + 'Wrapper'}
						isLocked = {props.isLocked}
						txMemoDraft = {props.txMemoDrafts[txRow.txID.toLowerCase()]}
						txFromDraft = {props.txFromDrafts[txRow.txID.toLowerCase()]}
						txToDraft = {props.txToDrafts[txRow.txID.toLowerCase()]}
						updateTxMemoDraft = {function(newDraft: string | undefined) {
							props.updateTxMemoDraft(txRow.txID, newDraft);
						}}
						updateTxFromDraft = {function(newDraft: string | undefined) {
							props.updateTxFromDraft(txRow.txID, newDraft);
						}}
						updateTxToDraft = {function(newDraft: string | undefined) {
							props.updateTxToDraft(txRow.txID, newDraft);
						}}
						rowData = {txRow}
						onMemoChange = {function(newMemoTrimmed: string) {
							return props.updateTxMemo(txRow.txID, newMemoTrimmed);
						}}
						useNarrowVersion = {true}
						updateAcctLabel = {props.updateAcctLabel}
						setFilterTo = {props.setFilterTo}
						queriedAddressesLowercase = {props.queriedAddressesLowercase}
						anySpecifiedTxes = {props.anySpecifiedTxes}
						timeRangeConstraintType = {props.timeRangeConstraintType}
					/>)
				}
		</ul>
	);
}
