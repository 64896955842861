import { EditableText } from './EditableText';

export const EditableMemo = function(props: {
	text ?: string; //default ''
	draft?: string; // undefined (default) if not editing
	updateDraft : (newDraft: string | undefined) => void; //undefined if not editing
	isLocked: boolean;
	onBlur : ((newText: string) => Promise<void>);
}) {
	return(
		<span className='memoInsideTD'>
			Memo:&nbsp;<EditableText
					{...props}
					placeholder = 'Add notes about this transaction...'
					draft={props.draft}
					updateDraft={props.updateDraft}
				/>
		</span>
	);
}
